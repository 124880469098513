// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-button-tooltip {
  width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-thread/query-history-item/interaction/query-history-interaction-content/chat/chat-events/chat-event/chat-event.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ","sourcesContent":[".delete-button-tooltip {\n    width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

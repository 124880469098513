// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-submission-textarea-field .blank-field {
  padding: 2px 12px;
}
.form-submission-textarea-field .details-field-value {
  margin-left: 12px;
  margin-top: 2px;
}
.form-submission-textarea-field p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/form-submission/form-submission-field/form-submission-textarea-field/form-submission-textarea-field.scss","webpack://./../../packages/styles/sass/_spacing.scss"],"names":[],"mappings":"AAGI;EACI,iBAAA;AAFR;AAKI;EACI,iBCGQ;EDFR,eCJY;ADCpB;AAMI;EACI,SAAA;AAJR","sourcesContent":["@use \"styles/spacing\";\n\n.form-submission-textarea-field {\n    .blank-field {\n        padding: spacing.$spacing-xxx-small spacing.$spacing-large;\n    }\n\n    .details-field-value {\n        margin-left: spacing.$spacing-large;\n        margin-top: spacing.$spacing-xxx-small;\n    }\n\n    p {\n        margin: 0;\n    }\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

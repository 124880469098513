// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-events-filters {
  display: flex;
}
.chat-events-filters .radio {
  width: auto;
}
.chat-events-filters .radio label {
  padding: 0;
}
.chat-events-filters .radio + .radio {
  margin-left: 16px;
}

.modal .chat-events-filters form {
  display: unset;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-thread/query-history-item/interaction/query-history-interaction-content/chat/chat-events/chat-events-filters/chat-events-filters.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAGI;EACI,WAAA;AADR;AAEQ;EACI,UAAA;AAAZ;AAGI;EACI,iBAAA;AADR;;AAKA;EACI,cAAA;EACA,SAAA;EACA,UAAA;AAFJ","sourcesContent":["@use \"styles/spacing\";\n\n.chat-events-filters {\n    display: flex;\n\n    .radio {\n        width: auto;\n        label {\n            padding: 0;\n        }\n    }\n    .radio + .radio {\n        margin-left: spacing.$spacing-x-large;\n    }\n}\n\n.modal .chat-events-filters form {\n    display: unset;\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

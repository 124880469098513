// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-section-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/details/details-section-title/details-section-title.scss","webpack://./../../node_modules/@edgetier/styles/sass/_spacing.scss","webpack://./../../packages/client-components/dist/details/details-section-title/details-section-title.css"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,oBCOc;EDNd,mBAAA;EACA,mBCIY;EDHZ,kBCGY;ACJhB","sourcesContent":["@use \"styles/spacing\";\n\n.details-section-title {\n    display: flex;\n    justify-content: space-between;\n    padding-bottom: spacing.$spacing-x-large;\n    align-items: center;\n    padding-right: spacing.$spacing-large;\n    padding-left: spacing.$spacing-large;\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n",".details-section-title {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 16px;\n  align-items: center;\n  padding-right: 12px;\n  padding-left: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

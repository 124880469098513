// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-variable-fields .details-field-title {
  display: block;
}
.chat-variable-fields__title-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.chat-variable-fields .details-field-value,
.chat-variable-fields .details-field-title span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-thread/query-history-item/interaction/common-details/chat-variable-fields/chat-variable-fields.scss"],"names":[],"mappings":"AAGI;EACI,cAAA;AAFR;AAII;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAFR;AAII;;EAEI,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,SAAA;AAFR","sourcesContent":["@use \"styles/spacing\";\n\n.chat-variable-fields {\n    .details-field-title {\n        display: block;\n    }\n    &__title-group {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        gap: spacing.$spacing-base;\n    }\n    .details-field-value,\n    .details-field-title span {\n        display: -webkit-box;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-urls__tooltip p:first-of-type {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/customer-urls/customer-urls.scss"],"names":[],"mappings":"AAEQ;EACI,aAAA;AADZ","sourcesContent":[".customer-urls {\n    &__tooltip {\n        p:first-of-type {\n            margin-top: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

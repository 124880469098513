// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  display: flex;
  padding-bottom: 14px;
}
.modal-header__content {
  flex: 1 1;
  min-width: 0;
}`, "",{"version":3,"sources":["webpack://./../../packages/modal/dist/modal-header/modal-header.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,oBAAA;AADJ;AAGI;EACI,SAAA;EAEA,YAAA;AAFR","sourcesContent":["@use \"../styles/variables\";\n\n.modal-header {\n    display: flex;\n    padding-bottom: calc(variables.$modal-padding / 2);\n\n    &__content {\n        flex: 1;\n        // This allows truncated text in a flex child: https://css-tricks.com/flexbox-truncated-text/.\n        min-width: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid__header {
  text-align: left;
  background-color: #ecf0f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px dashed #dde4e6;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/grid/header/header.scss","webpack://./../../node_modules/@edgetier/styles/sass/_colours.scss","webpack://./../../packages/client-components/dist/grid/header/header.css"],"names":[],"mappings":"AAGA;EACI,gBAAA;EACA,yBC8BU;ED7BV,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,iCAAA;AEFJ","sourcesContent":["@use \"styles/spacing\";\n@use \"styles/colours\";\n\n.grid__header {\n    text-align: left;\n    background-color: colours.$background-tertiary;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: spacing.$spacing-small;\n    border-bottom: 1px dashed colours.$divider;\n}\n","////\n/// Named colours used throughout all EdgeTier products\n/// @group Colours\n////\n\n/// Greens\n$flat-turquoise: #1abc9c;\n$flat-green-sea: #16a085;\n$flat-emerald: #2ecc71;\n$flat-nephritis: #27ae60;\n\n/// Blues\n$flat-peter-river: #3498db;\n$flat-belize-hole: #2980b9;\n$flat-wet-asphalt: #34495e;\n$flat-midnight-blue: #2c3e50;\n\n/// Purples\n$flat-amethyst: #9b59b6;\n$flat-wisteria: #8e44ad;\n\n/// Yellows\n$flat-sunflower: #f1c40f;\n\n/// Oranges\n$flat-orange: #f39c12;\n$flat-carrot: #e67e22;\n$flat-pumpkin: #d35400;\n\n/// Reds\n$flat-alizarin: #e74c3c;\n$flat-pomegranate: #c0392b;\n\n/// Grays\n$flat-white: #fff;\n$flat-clouds: #ecf0f1;\n$flat-silver: #bdc3c7;\n$flat-concrete: #95a5a6;\n$flat-asbestos: #7f8c8d;\n",".grid__header {\n  text-align: left;\n  background-color: #ecf0f1;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px;\n  border-bottom: 1px dashed #dde4e6;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

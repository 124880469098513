// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-submission-select-multiple-field--single,
.form-submission-select-multiple-field .blank-field {
  padding: 2px 12px;
}
.form-submission-select-multiple-field ul {
  list-style-position: inside;
  padding: 10px;
  margin-left: 15px;
  list-style-type: disc;
}`, "",{"version":3,"sources":["webpack://./src/components/form-submission/form-submission-field/form-submission-select-multiple-field/form-submission-select-multiple-field.scss"],"names":[],"mappings":"AAGI;;EAEI,iBAAA;AAFR;AAKI;EACI,2BAAA;EACA,aAAA;EACA,iBAAA;EACA,qBAAA;AAHR","sourcesContent":["@use \"styles/spacing\";\n\n.form-submission-select-multiple-field {\n    &--single,\n    .blank-field {\n        padding: spacing.$spacing-xxx-small spacing.$spacing-large;\n    }\n\n    ul {\n        list-style-position: inside;\n        padding: spacing.$spacing-base;\n        margin-left: 15px;\n        list-style-type: disc;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

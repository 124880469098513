// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application__authenticated-routes {
  display: flex;
}
.application__authenticated-routes, .application__authenticated-routes__pages, .application__authenticated-routes__page {
  height: 100%;
}
.application__authenticated-routes, .application__authenticated-routes__pages {
  overflow-x: hidden;
}
.application__authenticated-routes__pages {
  flex: 1 1;
  position: relative;
}
.application__authenticated-routes__page {
  will-change: transform, opacity;
  display: flex;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/application/authenticated-routes/authenticated-routes.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EAGI,YAAA;AADR;AAII;EAEI,kBAAA;AAHR;AAMI;EACI,SAAA;EACA,kBAAA;AAJR;AAOI;EACI,+BAAA;EACA,aAAA;EACA,SAAA;AALR","sourcesContent":[".application__authenticated-routes {\n    display: flex;\n\n    &,\n    &__pages,\n    &__page {\n        height: 100%;\n    }\n\n    &,\n    &__pages {\n        overflow-x: hidden;\n    }\n\n    &__pages {\n        flex: 1;\n        position: relative;\n    }\n\n    &__page {\n        will-change: transform, opacity;\n        display: flex;\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion__accordion-item-content .ReactCollapse--collapse {
  transition: height 300ms;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/accordion/accordion-item-content/accordion-item-content.scss","webpack://./../../packages/client-components/dist/accordion/accordion-item-content/accordion-item-content.css"],"names":[],"mappings":"AACI;EACI,wBAAA;ACAR","sourcesContent":[".accordion__accordion-item-content {\n    .ReactCollapse--collapse {\n        transition: height 300ms;\n    }\n}\n",".accordion__accordion-item-content .ReactCollapse--collapse {\n  transition: height 300ms;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid__menu-section {
  display: flex;
  flex-direction: column;
}
.grid__menu-section + .grid__menu-section {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #dde4e6;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/grid/table-menu-section/table-menu-section.scss","webpack://./../../packages/client-components/dist/grid/table-menu-section/table-menu-section.css","webpack://./../../node_modules/@edgetier/styles/sass/_spacing.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;ACFJ;ADII;EACI,gBEEO;EFDP,iBECO;EFAP,6BAAA;ACFR","sourcesContent":["@use \"styles/colours\";\n@use \"styles/spacing\";\n\n.grid__menu-section {\n    display: flex;\n    flex-direction: column;\n\n    & + & {\n        margin-top: spacing.$spacing-base;\n        padding-top: spacing.$spacing-base;\n        border-top: 1px solid colours.$divider;\n    }\n}\n",".grid__menu-section {\n  display: flex;\n  flex-direction: column;\n}\n.grid__menu-section + .grid__menu-section {\n  margin-top: 10px;\n  padding-top: 10px;\n  border-top: 1px solid #dde4e6;\n}\n\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.query-history-timeline > button {
  margin: 6px;
  width: calc(100% - 12px);
}
.query-history-timeline__current--empty {
  text-align: center;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-timeline/query-history-timeline.scss","webpack://./../../packages/styles/sass/_spacing.scss"],"names":[],"mappings":"AAGI;EACI,WCIU;EDHV,wBAAA;AAFR;AAKQ;EACI,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eCDI;ADFhB","sourcesContent":["@use \"styles/spacing\";\n\n.query-history-timeline {\n    > button {\n        margin: spacing.$spacing-x-small;\n        width: calc(100% - #{spacing.$spacing-x-small * 2});\n    }\n    &__current {\n        &--empty {\n            text-align: center;\n            font-style: italic;\n            font-weight: 400;\n            font-size: spacing.$spacing-large;\n        }\n    }\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#stacking-layers {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/application/stacking-layers/stacking-layers.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AACJ","sourcesContent":["#stacking-layers {\n    z-index: 10;\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 28px;
}`, "",{"version":3,"sources":["webpack://./../../packages/modal/dist/modal-footer/modal-footer.scss","webpack://./../../packages/client-components/dist/styles/_spacing.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBCJe;ADGnB","sourcesContent":["@use \"../styles/variables\";\n\n.modal-footer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding-top: variables.$modal-padding;\n}\n","@forward \"@edgetier/styles/sass/spacing\";\n\n$spacing-xx-large: 28px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

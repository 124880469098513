// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-warning__icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #c0392b;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/tooltip-warning/tooltip-warning.scss","webpack://./../../node_modules/@edgetier/styles/sass/_colours.scss","webpack://./../../packages/client-components/dist/tooltip-warning/tooltip-warning.css"],"names":[],"mappings":"AAMI;EACI,YAHG;EAIH,WAJG;EAKH,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cCkBW;ACvBnB","sourcesContent":["@use \"styles/colours\";\n@use \"styles/spacing\";\n\n.tooltip-warning {\n    $size: 24px;\n\n    &__icon {\n        height: $size;\n        width: $size;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border-radius: 4px;\n        color: colours.$flat-pomegranate;\n    }\n}\n","////\n/// Named colours used throughout all EdgeTier products\n/// @group Colours\n////\n\n/// Greens\n$flat-turquoise: #1abc9c;\n$flat-green-sea: #16a085;\n$flat-emerald: #2ecc71;\n$flat-nephritis: #27ae60;\n\n/// Blues\n$flat-peter-river: #3498db;\n$flat-belize-hole: #2980b9;\n$flat-wet-asphalt: #34495e;\n$flat-midnight-blue: #2c3e50;\n\n/// Purples\n$flat-amethyst: #9b59b6;\n$flat-wisteria: #8e44ad;\n\n/// Yellows\n$flat-sunflower: #f1c40f;\n\n/// Oranges\n$flat-orange: #f39c12;\n$flat-carrot: #e67e22;\n$flat-pumpkin: #d35400;\n\n/// Reds\n$flat-alizarin: #e74c3c;\n$flat-pomegranate: #c0392b;\n\n/// Grays\n$flat-white: #fff;\n$flat-clouds: #ecf0f1;\n$flat-silver: #bdc3c7;\n$flat-concrete: #95a5a6;\n$flat-asbestos: #7f8c8d;\n",".tooltip-warning__icon {\n  height: 24px;\n  width: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  color: #c0392b;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

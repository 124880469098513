// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.query-history-timeline-item-warning .tooltip-warning__trigger {
  position: absolute;
  top: -8px;
  right: -8px;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-timeline/query-history-timeline-item/query-history-timeline-item-warning/query-history-timeline-item-warning.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AAAR","sourcesContent":[".query-history-timeline-item-warning {\n    .tooltip-warning__trigger {\n        position: absolute;\n        top: -8px;\n        right: -8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 32px;
}
.permission-summary__description-info {
  padding: 2px;
}
.permission-summary__title {
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./../../packages/roles-permissions/dist/components/permissions/permission-summary/permission-summary.scss","webpack://./../../packages/styles/sass/_spacing.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SCIW;EDHX,gBAAA;AADJ;AAGI;EACI,YCLY;ADIpB;AAII;EACI,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AAFR","sourcesContent":["@use \"@edgetier/styles/sass/spacing\";\n\n.permission-summary {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: spacing.$spacing-base;\n    min-height: 2 * spacing.$spacing-x-large;\n\n    &__description-info {\n        padding: spacing.$spacing-xxx-small;\n    }\n\n    &__title {\n        line-height: 1.2;\n        display: -webkit-box;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n    }\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-authenticated-routes {
  display: flex;
  height: 100%;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/client-authenticated-routes/client-authenticated-routes.scss","webpack://./../../packages/client-components/dist/client-authenticated-routes/client-authenticated-routes.css"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;ACCJ","sourcesContent":[".client-authenticated-routes {\n    display: flex;\n    height: 100%;\n}\n",".client-authenticated-routes {\n  display: flex;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

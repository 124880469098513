// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-application {
  background-color: #f2f5f5;
  height: 100%;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/client-application/client-application.scss","webpack://./../../packages/client-components/src/styles/_colours.scss","webpack://./../../packages/client-components/dist/client-application/client-application.css"],"names":[],"mappings":"AAEA;EACI,yBCQmB;EDPnB,YAAA;AEDJ","sourcesContent":["@use \"styles/colours\";\n\n.client-application {\n    background-color: colours.$background-secondary;\n    height: 100%;\n}\n","@forward \"@edgetier/styles/sass/colours\";\n@use \"@edgetier/styles/sass/colours\" as *;\n@use \"sass:color\";\n\n$background-colour: lighten($flat-clouds, 2%);\n$divider-colour: darken($flat-clouds, 5%);\n$placeholder-colour: $flat-concrete;\n\n$color: $flat-midnight-blue;\n$color-secondary: $flat-wet-asphalt;\n$background: white;\n$background-secondary: lighten($flat-clouds, 2%);\n$background-tertiary: $flat-clouds;\n$divider: darken($flat-clouds, 5%);\n$label: $flat-asbestos;\n$medium: $flat-concrete;\n$pop-up-background: $flat-wet-asphalt;\n$pop-up-color: white;\n$scroll: $flat-concrete;\n$modal-overlay-background: linear-gradient(to top, rgba($flat-clouds, 0.2), rgba($flat-midnight-blue, 0.5));\n$loading-background: $flat-alizarin;\n$primary: $flat-peter-river;\n$primary-variant: $flat-belize-hole;\n$positive: $flat-green-sea;\n$positive-variant: darken($flat-green-sea, 5%);\n$negative: $flat-alizarin;\n$negative-variant: $flat-pomegranate;\n$neutral: $flat-asbestos;\n$neutral-variant: darken($flat-asbestos, 5%);\n$grey: $flat-clouds;\n$grey-variant: darken($flat-clouds, 5%);\n$disabled: $flat-clouds;\n$disabled-color: $flat-silver;\n$alert-background: lighten($flat-alizarin, 37%);\n$alert-color: $flat-alizarin;\n$alert-border: $flat-alizarin;\n",".client-application {\n  background-color: #f2f5f5;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-translation__button {
  font-weight: bold;
  white-space: nowrap;
}
.toggle-translation__button--disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
.toggle-translation__button label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.toggle-translation__button label,
.toggle-translation__button li {
  transition: opacity 250ms ease-out;
}
.toggle-translation__button li {
  display: inline-block;
  position: relative;
}
.toggle-translation__button svg,
.toggle-translation__button li + li {
  margin-left: 2px;
}
.toggle-translation__button input {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/shared/toggle-translation/toggle-translation-button/toggle-translation-button.scss","webpack://./../../packages/styles/sass/_spacing.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,mBAAA;AADJ;AAGI;EACI,YAAA;EACA,mBAAA;EACA,oBAAA;AADR;AAII;EACI,eAAA;EACA,aAAA;EACA,mBAAA;AAFR;AAKI;;EAEI,kCAAA;AAHR;AAMI;EACI,qBAAA;EACA,kBAAA;AAJR;AAOI;;EAEI,gBCzBY;ADoBpB;AAQI;EACI,aAAA;AANR","sourcesContent":["@use \"@edgetier/styles/sass/spacing\";\n\n.toggle-translation__button {\n    font-weight: bold;\n    white-space: nowrap;\n\n    &--disabled {\n        opacity: 0.3;\n        cursor: not-allowed;\n        pointer-events: none;\n    }\n\n    label {\n        cursor: pointer;\n        display: flex;\n        align-items: center;\n    }\n\n    label,\n    li {\n        transition: opacity 250ms ease-out;\n    }\n\n    li {\n        display: inline-block;\n        position: relative;\n    }\n\n    svg,\n    li + li {\n        margin-left: spacing.$spacing-xxx-small;\n    }\n\n    input {\n        display: none;\n    }\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.query-history-timeline-note__icon {
  opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-timeline/query-history-timeline-note/query-history-timeline-note.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR","sourcesContent":[".query-history-timeline-note {\n    &__icon {\n        opacity: 0.9;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

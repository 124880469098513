// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lazy-wrapper {
  min-height: 0;
  transition: min-height 0.25s linear;
}
.lazy-wrapper--placeholder {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/modal/lazy-wrapper/lazy-wrapper.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mCAAA;AACJ;AACI;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR","sourcesContent":[".lazy-wrapper {\n    min-height: 0;\n    transition: min-height 0.25s linear;\n\n    &--placeholder {\n        min-height: 50vh;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  Mixin for applying ellipsis to any element
*/
.box-subheading {
  font-size: 1.1rem;
  margin-top: 6px;
  color: #667273;
  font-weight: normal;
}

`, "",{"version":3,"sources":["webpack://./../../node_modules/@edgetier/styles/sass/_mixins.scss","webpack://./../../packages/client-components/src/box-subheading/box-subheading.scss","webpack://./../../node_modules/@edgetier/styles/sass/_spacing.scss","webpack://./../../packages/client-components/dist/box-subheading/box-subheading.css"],"names":[],"mappings":"AAAA;;CAAA;ACIA;EACI,iBAAA;EACA,eCEc;EDDd,cAAA;EACA,mBAAA;AEAJ","sourcesContent":["/*\n  Mixin for applying ellipsis to any element\n*/\n@mixin overflow-ellipis($maxWidth: null) {\n    @if ($maxWidth != null) {\n        max-width: $maxWidth;\n    }\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n","@use \"styles/variables\";\n@use \"styles/spacing\";\n@use \"styles/colours\";\n\n.box-subheading {\n    font-size: variables.$font-size-small;\n    margin-top: spacing.$spacing-x-small;\n    color: darken(colours.$label, 10%);\n    font-weight: normal;\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n","/*\n  Mixin for applying ellipsis to any element\n*/\n.box-subheading {\n  font-size: 1.1rem;\n  margin-top: 6px;\n  color: #667273;\n  font-weight: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

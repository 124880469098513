// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-blocker {
  transition: opacity 250ms ease-out;
  height: 100%;
}
.loading-blocker-blocked {
  pointer-events: none;
  opacity: 0.25;
}`, "",{"version":3,"sources":["webpack://./src/components/loading-blocker/loading-blocker.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,YAAA;AACJ;AAAI;EACI,oBAAA;EACA,aAAA;AAER","sourcesContent":[".loading-blocker {\n    transition: opacity 250ms ease-out;\n    height: 100%;\n    &-blocked {\n        pointer-events: none;\n        opacity: 0.25;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion__accordion-item {
  border-left: 3px solid transparent;
  transition: border-color 250ms ease-out;
}
.accordion__accordion-item--is-open {
  border-left-color: #16a085;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/accordion/accordion-item-container/accordion-item-container.scss","webpack://./../../packages/client-components/dist/accordion/accordion-item-container/accordion-item-container.css"],"names":[],"mappings":"AAEA;EACI,kCAAA;EACA,uCAAA;ACDJ;ADGI;EACI,0BAAA;ACDR","sourcesContent":["@use \"styles/colours\";\n\n.accordion__accordion-item {\n    border-left: 3px solid transparent;\n    transition: border-color 250ms ease-out;\n\n    &--is-open {\n        border-left-color: colours.$positive;\n    }\n}\n",".accordion__accordion-item {\n  border-left: 3px solid transparent;\n  transition: border-color 250ms ease-out;\n}\n.accordion__accordion-item--is-open {\n  border-left-color: #16a085;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

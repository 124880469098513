// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-details__version {
  color: #7f8c8d;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/device-details/device-details.scss","webpack://./../../packages/styles/sass/_colours.scss"],"names":[],"mappings":"AAGI;EACI,cCkCQ;ADpChB","sourcesContent":["@use \"styles/colours\";\n\n.device-details {\n    &__version {\n        color: colours.$flat-asbestos;\n    }\n}\n","////\n/// Named colours used throughout all EdgeTier products\n/// @group Colours\n////\n\n/// Greens\n$flat-turquoise: #1abc9c;\n$flat-green-sea: #16a085;\n$flat-emerald: #2ecc71;\n$flat-nephritis: #27ae60;\n\n/// Blues\n$flat-peter-river: #3498db;\n$flat-belize-hole: #2980b9;\n$flat-wet-asphalt: #34495e;\n$flat-midnight-blue: #2c3e50;\n\n/// Purples\n$flat-amethyst: #9b59b6;\n$flat-wisteria: #8e44ad;\n\n/// Yellows\n$flat-sunflower: #f1c40f;\n\n/// Oranges\n$flat-orange: #f39c12;\n$flat-carrot: #e67e22;\n$flat-pumpkin: #d35400;\n\n/// Reds\n$flat-alizarin: #e74c3c;\n$flat-pomegranate: #c0392b;\n\n/// Grays\n$flat-white: #fff;\n$flat-clouds: #ecf0f1;\n$flat-silver: #bdc3c7;\n$flat-concrete: #95a5a6;\n$flat-asbestos: #7f8c8d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

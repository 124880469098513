// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid__expand-button {
  font-size: 18px;
  cursor: pointer;
  transition: color 150ms ease-out;
  color: #7f8c8d;
}
.grid__expand-button:hover {
  color: #16a085;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/grid/expand-button/expand-button.scss","webpack://./../../node_modules/@edgetier/styles/sass/_colours.scss","webpack://./../../packages/client-components/dist/grid/expand-button/expand-button.css"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,eAAA;EACA,gCAAA;EACA,cCgCY;ACjChB;AFEI;EACI,cCDS;ACCjB","sourcesContent":["@use \"styles/colours\";\n\n.grid__expand-button {\n    font-size: 18px;\n    cursor: pointer;\n    transition: color 150ms ease-out;\n    color: colours.$label;\n    &:hover {\n        color: colours.$positive;\n    }\n}\n","////\n/// Named colours used throughout all EdgeTier products\n/// @group Colours\n////\n\n/// Greens\n$flat-turquoise: #1abc9c;\n$flat-green-sea: #16a085;\n$flat-emerald: #2ecc71;\n$flat-nephritis: #27ae60;\n\n/// Blues\n$flat-peter-river: #3498db;\n$flat-belize-hole: #2980b9;\n$flat-wet-asphalt: #34495e;\n$flat-midnight-blue: #2c3e50;\n\n/// Purples\n$flat-amethyst: #9b59b6;\n$flat-wisteria: #8e44ad;\n\n/// Yellows\n$flat-sunflower: #f1c40f;\n\n/// Oranges\n$flat-orange: #f39c12;\n$flat-carrot: #e67e22;\n$flat-pumpkin: #d35400;\n\n/// Reds\n$flat-alizarin: #e74c3c;\n$flat-pomegranate: #c0392b;\n\n/// Grays\n$flat-white: #fff;\n$flat-clouds: #ecf0f1;\n$flat-silver: #bdc3c7;\n$flat-concrete: #95a5a6;\n$flat-asbestos: #7f8c8d;\n",".grid__expand-button {\n  font-size: 18px;\n  cursor: pointer;\n  transition: color 150ms ease-out;\n  color: #7f8c8d;\n}\n.grid__expand-button:hover {\n  color: #16a085;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expand-collapse-arrow {
  transform-origin: center;
  transform: rotate(0);
  transition: transform 0.2s linear;
}
.expand-collapse-arrow--up {
  transform: rotate(180deg);
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/expand-collapse-arrows/expand-collapse-arrows.scss","webpack://./../../packages/client-components/dist/expand-collapse-arrows/expand-collapse-arrows.css"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,oBAAA;EACA,iCAAA;ACCJ;ADCI;EACI,yBAAA;ACCR","sourcesContent":[".expand-collapse-arrow {\n    transform-origin: center;\n    transform: rotate(0);\n    transition: transform 0.2s linear;\n\n    &--up {\n        transform: rotate(180deg);\n    }\n}\n",".expand-collapse-arrow {\n  transform-origin: center;\n  transform: rotate(0);\n  transition: transform 0.2s linear;\n}\n.expand-collapse-arrow--up {\n  transform: rotate(180deg);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

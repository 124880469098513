// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachments__item:not(.attachments--unavailable) button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/attachments/attachments.scss"],"names":[],"mappings":"AAEQ;EACI,eAAA;AADZ","sourcesContent":[".attachments {\n    &__item:not(.attachments--unavailable) {\n        button {\n            cursor: pointer;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

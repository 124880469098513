// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sidebar {
  background-color: white;
  flex-grow: 1;
  min-width: 350px;
  max-width: 25%;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/page-sidebar/page-sidebar.scss","webpack://./../../packages/client-components/dist/page-sidebar/page-sidebar.css"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;ACCJ","sourcesContent":[".page-sidebar {\n    background-color: white;\n    flex-grow: 1;\n    min-width: 350px;\n    max-width: 25%;\n}\n",".page-sidebar {\n  background-color: white;\n  flex-grow: 1;\n  min-width: 350px;\n  max-width: 25%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline button[type=submit] {
  position: absolute;
  left: -9999px;
}`, "",{"version":3,"sources":["webpack://./src/components/timeline/timeline.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,aAAA;AAAR","sourcesContent":[".timeline {\n    button[type=\"submit\"] {\n        position: absolute;\n        left: -9999px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

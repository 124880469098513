// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  box-shadow: inset 2px 0 3px -1px rgba(0, 0, 0, 0.075);
  overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/page/page.scss","webpack://./../../packages/client-components/dist/page/page.css"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,qDAAA;EACA,gBAAA;ACCJ","sourcesContent":[".page {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    box-shadow: inset 2px 0 3px -1px rgba(0, 0, 0, 0.075);\n    overflow: hidden;\n}\n",".page {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  box-shadow: inset 2px 0 3px -1px rgba(0, 0, 0, 0.075);\n  overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsis-menu__item--has-link {
  padding: 0;
}
.ellipsis-menu__item-link {
  width: 100%;
  padding: 0 12px;
}

`, "",{"version":3,"sources":["webpack://./../../packages/client-components/src/ellipsis-menu/ellipsis-menu-item/ellipsis-menu-item.scss","webpack://./../../packages/client-components/dist/ellipsis-menu/ellipsis-menu-item/ellipsis-menu-item.css"],"names":[],"mappings":"AAGI;EACI,UAAA;ACFR;ADKI;EACI,WAAA;EACA,eAAA;ACHR","sourcesContent":["@use \"styles/spacing\";\n\n.ellipsis-menu {\n    &__item--has-link {\n        padding: 0;\n    }\n\n    &__item-link {\n        width: 100%;\n        padding: 0 spacing.$spacing-large;\n    }\n}\n",".ellipsis-menu__item--has-link {\n  padding: 0;\n}\n.ellipsis-menu__item-link {\n  width: 100%;\n  padding: 0 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

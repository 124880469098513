// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redact-form p {
  margin-top: 0;
  margin-bottom: 12px;
}
.redact-form__buttons {
  margin-left: auto;
}
.redact-form button[type=reset] {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/redact-action/redact-form/redact-form.scss","webpack://./../../packages/styles/sass/_spacing.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,mBCMQ;ADRhB;AAKI;EACI,iBAAA;AAHR;AAMI;EACI,kBCHO;ADDf","sourcesContent":["@use \"styles/spacing\";\n\n.redact-form {\n    p {\n        margin-top: 0;\n        margin-bottom: spacing.$spacing-large;\n    }\n\n    &__buttons {\n        margin-left: auto;\n    }\n\n    button[type=\"reset\"] {\n        margin-right: spacing.$spacing-base;\n    }\n}\n","////\n/// Spacing defaults for all apps and component libraries\n/// @group Spacing\n////\n\n$spacing-increment: 2px !default;\n$spacing-xxx-small: $spacing-increment !default; // 2px\n$spacing-xx-small: 2 * $spacing-increment !default; // 4px\n$spacing-x-small: 3 * $spacing-increment !default; // 6px\n$spacing-small: 4 * $spacing-increment !default; // 8px\n$spacing-base: 5 * $spacing-increment !default; // 10px\n$spacing-large: 6 * $spacing-increment !default; // 12px\n$spacing-x-large: 8 * $spacing-increment !default; // 16px\n$spacing-xx-large: 2 * $spacing-x-large !default; // 32px\n$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px\n\n// Padding for all form fields.\n$field-padding: $spacing-large !default;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.raw-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/components/raw-text/raw-text.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,yBAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;AACJ","sourcesContent":[".raw-text {\n    white-space: pre-wrap;\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n    -ms-word-break: break-all;\n    word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

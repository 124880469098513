// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-details__interaction-url {
  max-width: 0;
  width: 100%;
}
.common-details__interaction-url .details-field-value__value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components-for/query-history/query-history-thread/query-history-item/interaction/common-details/common-details.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,WAAA;AAAR;AAEQ;EACI,gBAAA;EACA,mBAAA;EACA,uBAAA;AAAZ","sourcesContent":[".common-details {\n    &__interaction-url {\n        max-width: 0;\n        width: 100%;\n\n        .details-field-value__value {\n            overflow: hidden;\n            white-space: nowrap;\n            text-overflow: ellipsis;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
